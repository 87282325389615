/**
 * Main javascript file for CLIA
 * This file uses revealing module pattern
 */

import $ from "jquery";//89KB

import gsap, { Expo } from "gsap"; //still 89KB?
import ScrollTrigger from "gsap/ScrollTrigger";//194KB
import {initTranslations} from './translations';//287KB

import { tns } from "tiny-slider/src/tiny-slider"//288KB

import { Fancybox } from "@fancyapps/ui";//375KB
import "@lottiefiles/lottie-player";

gsap.registerPlugin(ScrollTrigger);

var clia = function($) {

	var $body = $('body'),
		$window = $(window),
		videoLoadedCount = 0,
		sliders = [],
		isMobile = $window.width()<768,
		isiOS = !!navigator.platform.match(/iPhone|iPod|iPad/);

    var init = async function() {
	    initialiseVideos();
	    initialisePreloader();
	    await initTranslations(onLanguageChange);
	    initialiseGlobal();
	    initialiseLanguage();
	    initialiseIntro();
	    initialiseSection1();
	    initialiseSection2();
	    initialiseSection3();
	    initialiseSliders();
	    initialiseFancybox();
	    initialiseVideo();
	    initialiseRegister();
    }

    var onLanguageChange = function(language) {

		//the 'explore spain' section is only loaded when we're on the english language
		$('#section3 .en-extras').toggle(language=='en');
		
		//the 'Charting the Future of Sustainable Cruise Travel download is only available on the english site
		$('#section1 .sidebar .en-extras').toggle(language=='en');

		var needtoInit = false;

		//loop through the existing sliders and destroy them
		if (sliders.length) {
			$(sliders).each(function(index, slider) {
				slider.destroy();
			});
			sliders = [];
			needtoInit = true;
		}

		//load in content for specified language

		//list of sliders we're going to replace
		var sliders2 = ['news1', 'news2', 'news3', 'ships'];
		$(sliders2).each(function(index, val) {

			var $container = $('.carousel-container.'+val);
			if ($container.length) {
				$container.empty();
				$.ajax({
				  dataType: "html",
				  async: false,
				  url: "/content/sliders/"+language+"/"+val+".htm",
				  success: function(html) {
					  $container.html(html);
				  }
			  	});
			}
		});
		if (needtoInit) {
	    	initialiseSliders();
	    	initialiseFancybox();
	    }

	}

    /*
     * set up videos - source of videos changes when we're on mobile
     */
    var initialiseVideos = function() {

		var current = 'desktop';

		if (isMobile) {
			//console.log('we\'re on mobile');
			current = 'mobile';
			updateVideos(current);
		}

		let portrait = window.matchMedia("screen and (orientation: portrait)");

		if (isiOS) {
			portrait.addListener((e) => {
		      if (e.matches) {
			        // Portrait mode
			        //console.log('switched to portrait');
			        if (current=='desktop') {
						//console.log('need to switch to mobile');
						current = 'mobile';
						updateVideos(current);
					}
			    } else {
			        // Landscape
			        //console.log('switched to landscape');
			        if (current=='mobile') {
						//console.log('need to switch to desktop');
						current = 'desktop';
						updateVideos(current);
					}
			    }
		    });
		} else {
			portrait.addEventListener("change", function(e) {
			    if (e.matches) {
			        // Portrait mode
			        //console.log('switched to portrait');
			        if (current=='desktop') {
						//console.log('need to switch to mobile');
						current = 'mobile';
						updateVideos(current);
					}
			    } else {
			        // Landscape
			        //console.log('switched to landscape');
			        if (current=='mobile') {
						//console.log('need to switch to desktop');
						current = 'desktop';
						updateVideos(current);
					}
			    }
			});
		}
	}

	var updateVideos = function(platform) {
		var prepend = platform=='mobile' ? 'mobile_' : '';
		$('section.video video').each(function() {
		    this.pause();
		});

		$('#section1 video source').attr('src', 'assets/video/'+prepend+'video1.mp4');
		$('#section2 video source').attr('src', 'assets/video/'+prepend+'video2.mp4');
		$('#section3 video source').attr('src', 'assets/video/'+prepend+'video3.mp4');

		$('section.video video').each(function() {
		    this.load();
		});
	}


	var videoLoaded = function() {
		videoLoadedCount++;
		//console.log(videoLoadedCount+' videos loaded');
		if (videoLoadedCount==3) {
			$('#preloader').fadeOut();
		}
	}

    /*
     * preloader (might need to move this since this script is loaded using defer)
     */
    var initialisePreloader = function() {

		var iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/),
			//https://stackoverflow.com/questions/50051639/javascript-html5-video-event-canplay-not-firing-on-safari
    		eventToCheck = iOSDevice ? 'loadedmetadata' : 'canplay';
    	//alert('ios = '+iOSDevice);
		//alert(eventToCheck);
		$('.video > video').each(function(index) {
	    	this.addEventListener('canplaythrough', (event) => {
        		//console.log('main.js caught canplaythrough');
        		//videoLoaded();
    		});

    		this.addEventListener(eventToCheck, (event) => {
    		  //console.log('main.js caught canplay');
    		  //alert('caught '+eventToCheck)
    		  videoLoaded();
    		});

		    // If the video is in the cache of the browser,
		    // the 'canplaythrough' event might have been triggered
		    // before we registered the event handler.
			if (this.readyState > 3) {
	      		//console.log('main.js video '+(index+1)+' is ready');
        		videoLoaded();
	    	}
		});

		//setTimeout(function() {
		//	$('#preloader').fadeOut();
		//}, 3000);
	}

    /*
     * global
     */
    var initialiseGlobal = function() {

    	//fix for viewport height units
    	//ref: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    	var vh = window.innerHeight;
    	// Then we set the value in the --vh custom property to the root of the document
    	document.documentElement.style.setProperty('--vh', vh+'px');

    	//update the screen height variable as the window resizes. Use debounce to prevent this firing hundreds of times
    	$(window).on('resize', debounce(function() {
    		//var vh = window.innerHeight;
    		//document.documentElement.style.setProperty('--vh', vh+'px');
    		//console.log('caught window resize: ',vh);

    		//because we are delaying / smoothing the resize by 500ms
    		setTimeout(function() {
				ScrollTrigger.refresh();
			}, 501);
    	}, 150));
    	/*
    	$(window).on('resize', function() {
    		var vh = window.innerHeight;
    		document.documentElement.style.setProperty('--vh', vh+'px');
    		//console.log('caught window resize: ',vh);
    	});*/

        //side navigation
        $('#navigation ul a').on('click', function(e) {
        	e.preventDefault();
        	var $this = $(this),
        		$target = $($this.attr('href'));
        	if ($target.length) {
				animateScroll($target);
        	}
        });

		$('.popup-trigger').on('click', function() {
			var $this = $(this),
				$target = $($this.data('target'));

			$('.popup.active, .hotspot.active').not($this).not($target).removeClass('active');
			$target.toggleClass('active');
			$this.toggleClass('active');
			$('.popup.more').removeClass('more');
		});

		//these popup close buttons only appear on mobile
		$('.popup button.close').on('click', function() {
			$('.popup.active, .hotspot.active').removeClass('active');
			$('.popup.more').removeClass('more');
		});

		//read more functionality on hotspots
		$('.popup button.more').on('click', function() {
			$(this).closest('.popup').addClass('more');
		});

		//any sections that contain a 'scroll tip'
    	$('section .scroll-tip').on('click', function() {
			animateScroll($(this).parent().next('section'));
        });

        //animation defaults
        gsap.defaults({
		  ease: "power3.inOut",
		  duration: 2
		});

		//open / close hotspots
		$('section button.hotspots-trigger').on('click', function() {
			var $section = $(this).closest('section');
			$section.find('.content').fadeOut();
			$section.find('.hotspots').fadeIn();
			$section.addClass('hotspots');
			$body.addClass('mobile-dark');
		});

		$('section .hotspots button.back').on('click', function() {
			var $section = $(this).closest('section');
			$section.find('.hotspots').fadeOut();
			$section.find('.content').fadeIn();
			$section.removeClass('hotspots');
			$body.removeClass('mobile-dark');
		});

		//close any popups when we click on the background
		$('section .hotspots').on('click', function(e) {
			//console.log($(e.target).attr('class'));
			if ($(e.target).hasClass('hotspots')) {
				$(this).find('.popup, .hotspot').removeClass('active');
     		}
		});

		//open / close sidebar
		$('.sidebar-trigger').on('click', function() {
			var $this = $(this);
			$this.closest('section').find('.sidebar').not('#downloads').addClass('active');
			$('body').addClass('sidebar');

			//focus the sidebar so cursor keys scroll the sidebar not main window
			setTimeout(function() {
				if ($this.closest('section').find('.sidebar.active .scroll-outer').length) {
					$this.closest('section').find('.sidebar.active .scroll-outer')[0].focus();
				}
			}, 301);
		});

		$('.sidebar button.close').on('click', function() {
			$('.sidebar').removeClass('active');
			$('body').removeClass('sidebar');
			$('.scroll-outer').scrollTop(0);
		});

		//glow buttons
		document.querySelectorAll(".glow-button").forEach((button) => {
		    const gradientElem = document.createElement("div");
		    gradientElem.classList.add("gradient");

		    button.appendChild(gradientElem);

			if (!isMobile) {
			  button.addEventListener("pointermove", (e) => {
			    const rect = button.getBoundingClientRect();

			    const x = e.clientX - rect.left;
			    const y = e.clientY - rect.top;

			    gsap.to(button, {
			      "--pointer-x": `${x}px`,
			      "--pointer-y": `${y}px`,
			      ease: "power1.out",
			      duration: 0.6,
			    });

			    gsap.to(button, {
			      "--button-glow": chroma
			        .mix(
			          getComputedStyle(button)
			            .getPropertyValue("--button-glow-start")
			            .trim(),
			          getComputedStyle(button).getPropertyValue("--button-glow-end").trim(),
			          x / rect.width
			        )
			        .hex(),
			      ease: "power1.out",
			      duration: 0.2,
			    });
			  });
			}
		});

		//open downloads sidebar
		$('.downloads-trigger').on('click', function() {
			$('#downloads').addClass('active');
			$('body').addClass('sidebar');

			//focus the sidebar so cursor keys scroll the sidebar not main window
			setTimeout(function() {
				if ($('#downloads .scroll-outer').length) {
					$('#downloads .scroll-outer')[0].focus();
				}
			}, 301);
		});

    };

    // Debounce
    var debounce = function(func, time) {
        var time = time || 100; // 100 by default if no param
        var timer;
        return function(event){
            if(timer) clearTimeout(timer);
            timer = setTimeout(func, time, event);
        };
    }

    //to animate a scroll
    var animateScroll = function($target) {
    	//we add remove this class which switches off the scroll snap
		$('html').addClass('scrolling');
		$('html, body').animate({
            scrollTop: $target.offset().top
        }, 600, 'swing', function() {
    		$('html').removeClass('scrolling');
        });
    }

    /**
     * #language selector
     */
    var initialiseLanguage = function() {

    	var s_nav = gsap.timeline({
	        scrollTrigger: {
	            trigger: "#language",
	            //markers: true,
	            start: "top center",
	            toggleActions: isMobile ? 'play none none none' : 'restart reset restart reset'
	        }
	    })
		.from("#language .content", {y: 150, autoAlpha: 0 });

    	$('#language button').on('click', function() {
			animateScroll($('#intro'));
        });
    }

    /**
     * #intro
     */
    var initialiseIntro = function() {

    	var s_nav = gsap.timeline({
	        scrollTrigger: {
	            trigger: "#intro",
	            //markers: true,
	            start: "top center",
	            toggleActions: isMobile ? 'play none none none' : 'restart reset restart reset',
			    onEnter: () => $body.addClass('language-chooser'),
			    onLeaveBack: () => $body.removeClass('language-chooser'),
	        }
	    })
		.from("#intro .content", {y: 150, autoAlpha: 0 });
    }

    /**
     * #section1
     */
    var initialiseSection1 = function() {
		const video = $('#section1 video')[0],
			$section = $("#section1");
		var playPromise;
        gsap.timeline({
	        scrollTrigger: {
	            trigger: "#section1",
	            //markers: true,
	            start: "top center", // when the top of the trigger hits the center of the viewport
		    	end: 'bottom top',
	            toggleActions: isMobile ? 'play none none none' : 'restart reset restart reset',
			    onEnter: () => {
					playPromise = onEnter(video);
					$body.addClass('dark');
				},
			    onEnterBack: () => {
					playPromise = onEnter(video);
				},
			    onLeave: () => onLeave(video, playPromise, $section),
			    onLeaveBack: () => {
					onLeave(video, playPromise, $section);
					$body.removeClass('dark');
				},
	        }
	    })
	    .to('#section1', {backgroundImage:'radial-gradient(circle at center, rgba(255,255,255, .6) 0%, rgba(255,255,255, .4) 25%, transparent 100%)'})
    	.from('#section1 .content', {y: 150, autoAlpha: 0 }, "<");//"<" The start of previous animation**

    }

    var onEnter = function(video) {
		return video.play();
	}

    var onLeave = function(video, playPromise, $section) {
		if (playPromise !== undefined) {
	    	playPromise.then(_ => {
				video.pause();
				video.currentTime = 0;
		    })
		    .catch(error => {
		      // Auto-play was prevented
		      // Show paused UI.
		      console.log('play promise failed');
		    });
		}
		resetSection($section);
	}

    //reset a section so the animations can start again
    var resetSection = function($section) {
		if (typeof $section.find == 'function') {
			$section.find('.hotspots').hide();
			//$('#section1').find('.hotspots').hide();
			$section.find('.popup, .hotspot, .sidebar, .subsidebar').removeClass('active');
			$section.find('.content').show();
			$section.removeClass('hotspots');
		}
	}

    /**
     * #section2
     */
    var initialiseSection2 = function() {
		const video = $('#section2 video')[0],
			$section = $("#section2");
		var playPromise;
		gsap.timeline({
	        scrollTrigger: {
	            trigger: "#section2",
	            //markers: true,
	            start: "top center", // when the top of the trigger hits the center of the viewport
		    	end: 'bottom top',
	            toggleActions: isMobile ? 'play none none none' : 'restart reset restart reset',
			    onEnter: () => {
					playPromise = onEnter(video);
				},
			    onEnterBack: () => {
					playPromise = onEnter(video);
				},
			    onLeave: () => onLeave(video, playPromise, $section),
			    onLeaveBack: () => onLeave(video, playPromise, $section),
	        }
	    })
	    .to('#section2', {backgroundImage:'radial-gradient(circle at center, rgba(255,255,255, .6) 0%, rgba(255,255,255, .4) 25%, transparent 100%)'})
    	.from("#section2 .content", {y: 150, autoAlpha: 0 }, "<");//"<" The start of previous animation**
    }

    /**
     * #section3
     */
    var initialiseSection3 = function() {
		const video = $('#section3 video')[0],
			$section = $("#section3");
		var playPromise;
		gsap.timeline({
	        scrollTrigger: {
	            trigger: "#section3",
	            //markers: true,
	            start: "top center", // when the top of the trigger hits the center of the viewport
		    	end: 'bottom top',
	            toggleActions: isMobile ? 'play none none none' : 'restart reset restart reset',
			    onEnter: () => {
					playPromise = onEnter(video);
				},
			    onEnterBack: () => {
					playPromise = onEnter(video);
					$body.addClass('dark');
				},
			    onLeave: () => {
					onLeave(video, playPromise, $section);
					$body.removeClass('dark');
				},
			    onLeaveBack: () => onLeave(video, playPromise, $section),
	        }
	    })
	    .to('#section3', {backgroundImage:'radial-gradient(circle at center, rgba(255,255,255, .6) 0%, rgba(255,255,255, .4) 25%, transparent 100%)'})
    	.from("#section3 .content", {y: 150, autoAlpha: 0 }, "<");//"<" The start of previous animation**

    }

    //init sliders
	var initialiseSliders = function() {

    	//small sliders
    	var $sliders = $('#section2 .news-slider, #section3 .news-slider');
      	$sliders.each(function() {
			var isExtra = $(this).parents('.expore-spain').length;
          	var slider = tns({
	            container: this,
			    items: 1,
			    slideBy: 'page',
			    autoplay: false,
			    autoplayButtonOutput: false,
			    nav: true,
			    navPosition: "bottom",
			    controls: true,
			    controlsPosition: "bottom",
			    controlsText: ['<svg class="icon prev"><use xlink:href="#ic_arrow"></use></svg>', '<svg class="icon next"><use xlink:href="#ic_arrow"></use></svg>'],
			    loop: false,
			    //autoHeight: true,
			    edgePadding: 30,
	  			gutter: 20,
         	});
          	//add to slider array
          	if (!isExtra) {
          		sliders.push(slider);
          	}
		});

		//large sliders
		//$sliders = $('#news .news-slider, #ships .ship-slider');
		$sliders = $('#news .news-slider');
      	$sliders.each(function() {
	        var slider = tns({
	            container: this,
			    items: 1,
			    slideBy: 'page',
			    autoplay: false,
			    autoplayButtonOutput: false,
			    nav: false,
			    controls: true,
			    controlsPosition: "bottom",
			    controlsText: ['<svg class="icon prev"><use xlink:href="#ic_arrow"></use></svg>', '<svg class="icon next"><use xlink:href="#ic_arrow"></use></svg>'],
			    loop: true,
			    //autoHeight: true,
			    responsive: {
			      768: {
			        //items: 3,
					gutter: 40,
	  				fixedWidth: 363,
	  				slideBy: 1
			      }
			    }
			});
	        sliders.push(slider);
		});
    }

    /**
     * Fancybox popups
     */
    var initialiseFancybox = function() {

		//console.log('initialiseFancybox, ', $('footer .prev').text(), $('footer .next').text());

		Fancybox.unbind('[data-fancybox^="news"]');
		Fancybox.unbind('[data-fancybox="ships"]');
		Fancybox.unbind('[data-fancybox="vimeo"]');

		//for popups
		Fancybox.bind('[data-fancybox^="news"]', {
	        preload: 0,
	        infinite: false,
	        hideScrollbar: false,
	        mainClass: 'news',
		    Carousel: {
			    Navigation: {
			        prevTpl: '<svg class="icon prev"><use xlink:href="#ic_fancybox_nav"></use></svg>'+$('footer .prev').text(),
			        nextTpl: $('footer .next').text()+'<svg class="icon prev"><use xlink:href="#ic_fancybox_nav"></use></svg>',
			    },
		    },
		    Toolbar: {
			    display: ["close"],
			},
			Hash: false
		});

		Fancybox.bind('[data-fancybox="ships"]', {
	        preload: 0,
	        infinite : false,
	        hideScrollbar : false,
	        mainClass  : 'ships',
		    Carousel: {
			    Navigation: {
			        prevTpl: '<svg class="icon prev"><use xlink:href="#ic_fancybox_nav"></use></svg>'+$('footer .prev').text(),
			        nextTpl: $('footer .next').text()+'<svg class="icon prev"><use xlink:href="#ic_fancybox_nav"></use></svg>',
			    },
		    },
			Hash: false
		});

		//small videos
		Fancybox.bind('[data-fancybox="vimeo"]', {
	        preload: 0,
	        infinite : false,
	        hideScrollbar : false,
			Hash: false
		});

    }

    /**
     * #video
     */
    var initialiseVideo = function() {

		/*
		$('.video-wrap button').on('click', function() {
			var $this = $(this);
			//$iframe.attr('src', $iframe.data('video')).fadeIn();
			$this.parent('.video-wrap').addClass('playing')
		});*/

		$('body').on('click', '.video-wrap button', function() {
			var $this = $(this);
			//$iframe.attr('src', $iframe.data('video')).fadeIn();
			$this.parent('.video-wrap').addClass('playing')
		});

		/*
		var $wrap = $('#video .video-wrap');

		function vimeoPost(action, value, target) {
			var data = { method: action };
			if (value) {
				data.value = value;
			}
			target.postMessage(JSON.stringify(data), '*');
		}

	    window.addEventListener('message', function(event) {

		    var data = JSON.parse(event.data);
		    //var data = event.data;
		    if (data.event) {
				//console.log(data);
				//console.log('source:', event.source);
		    }
		    if (data.event && data.event === "ready") {
 				var sender = event.source;
 				vimeoPost('addEventListener','play',sender);
		    }
		    if (data.event == 'play') {
		    	console.log('playing');
		    	$wrap.addClass('playing');
		    }
		}, false);

		//to allow us to add a loading animation
		window.focus();
		window.addEventListener("blur", () => {
		  setTimeout(() => {
		    if (document.activeElement.tagName === "IFRAME") {
		      $wrap.addClass('loading');

		      //TEMP
    		  setTimeout(function() {
				$wrap.addClass('playing');
			  }, 1000);

		    }
		  });
		}, { once: true });*/
    }

    /**
     * #register
     */
    var initialiseRegister = function() {

    	var $form = $('#register form');
    	$form.on('submit', function(e) {
        	e.preventDefault();

	        //check the recaptcha and submit data
	        $.ajax({
	          type: "POST",
	          url: 'https://www.cruiseinfohub.com/ajax/email.php',
	          data: $form.serialize(),
	          success: function(response) {
				  if (response.message=='Please try again later') {
					response.message = $('footer .try-again').text();
				  }
				  $form.find('.response p').text(response.message);
				  $form.find('.response').fadeIn();

	          },
	        });

	    });
	}


    return{init:init}//return items that are available outside

}($);

//document ready
$(function() {
	clia.init();
});
